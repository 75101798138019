<script lang="jsx">
import userSalary from '@/components/user-salary/index.vue'

const commission = Object.assign({}, userSalary, {
  data () {
    return Object.assign({}, userSalary.data(), {
      pageType: 1,
      isServe: true,
      pageTitle: '岗位佣金管理'
    })
  }
})

export default commission
</script>
